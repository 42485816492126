<template>
  <div id="rDeviceConfig" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
    <div class="c-container-box">
      <el-table :data="dataArrFilter">
        <el-table-column prop="title" label="设备名称" width="200"></el-table-column>
        <el-table-column prop="uid" label="设备UID" width="200"></el-table-column>
        <el-table-column prop="videoConfig.url" label="视频地址"></el-table-column>
        <el-table-column prop="videoConfig.id" label="视频ID"></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editReady(scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="dataArr.length" v-show="dataArr.length>0" @size-change="sizeChange" @current-change="pageChange">
      </el-pagination>
    </div>
    <!-- 设备配置 -->
    <Dialog :data="editDialog" @sure="sure">
      <div style="padding: 0 10%;">
        <div class="c-item">
          <span>设备名称：</span>
          <el-input v-model="editDialog.data.name" disabled></el-input>
        </div>
        <div class="c-item">
          <span>设备UID：</span>
          <el-input v-model="editDialog.data.uid" disabled></el-input>
        </div>
        <div class="c-item">
          <span>视频地址：</span>
          <el-input type="textarea" :rows="2" v-model="editDialog.data.videoUrl"></el-input>
        </div>
        <div class="c-item">
          <span>视频ID：</span>
          <el-input v-model="editDialog.data.videoId"></el-input>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../../components/Dialog.vue';

export default {
  data() {
    return {
      // 编辑dialog
      editDialog: {
        show: false,
        title: '设备配置',
        width: '600px',
        data: {
          name: '',
          uid: '',
          videoUrl: '',
          videoId: ''
        }
      },
      loading: false,
      // page
      page: {
        size: 10,
        curPage: 1
      }
    }
  },
  components: {
    Dialog
  },
  computed: {
    dataArr() {
      return this.$store.getters.getCurDevices;
    },
    dataArrFilter() {
      let page = this.page;
      let arr = this.dataArr.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    }
  },
  watch: {
    dataArr() {
      this.page.curPage = 1;
      this.dataRequest();
    }
  },
  methods: {
    dataRequest() {
      let uids = "";
      let devices = this.dataArr;
      this.dataArrFilter.forEach((item) => {
        uids += item.id + ","
      })
      let json = {
        "cmd": "dataStore",
        "id": uids,
        "tag": "video"
      }
      this.ws.send(json);
    },

    // 编辑
    editReady(item) {
      this.editDialog.show = true;
      this.editDialog.data = {
        name: item.title,
        uid: item.id,
        videoUrl: item.videoConfig.url,
        videoId: item.videoConfig.id
      }
    },
    // 确定
    sure() {
      let data = this.editDialog.data;
      if (!data.videoUrl) {
        this.common.cToast(this, '请输入视频地址');
        return;
      }
      let json = {
        "cmd": "setDataStore",
        "id": data.uid,
        "tag": "video",
        "data": {
          "url": data.videoUrl,
          "id": data.videoId ? data.videoId : ""
        }
      }
      this.ws.send(json);
      this.loading = true;
    },

    // 分页——当前页改变
    sizeChange(size) {
      this.page.size = size;
      this.page.curPage = 1;
      this.dataRequest();
    },
    pageChange(curPage) {
      this.page.curPage = curPage;
      this.dataRequest();
    }
  },
  mounted() {
    this.dataRequest();

    this.ws.addCallback('dataStoreAck', (res) => {
      if (!this.common.cGetResult(res)) {
        return;
      }
      console.log(333, res);
      for (let item of res.items) {
        let data = item.data;
        if (JSON.stringify(data) != "{}") {
          let device = this.common.cFindObj(this.dataArr, "id", item.id);
          if (device != null) {
            device.videoConfig = {
              url: data.url,
              id: data.id
            }
          }
        }
      }
    })

    this.ws.addCallback('setDataStoreAck', (res) => {
      this.loading = false;
      if (!this.common.cGetResult(res)) {
        this.common.cResultNo(this);
        return;
      }
      let device = this.common.cFindObj(this.dataArr, "id", res.id);
      if (device != null) {
        device.videoConfig = {
          url: this.editDialog.data.videoUrl,
          id: this.editDialog.data.videoId
        }
      }
      this.common.cResultOk(this);
      this.editDialog.show = false;
    })
  }
}
</script>

<style lang="scss">
#rDeviceConfig {
  .c-container-box {
    margin-top: 0;
  }
}
</style>
